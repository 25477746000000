import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import userReducer from './slices/user';
import clientReducer from './slices/client';
import productReducer from './slices/product';
import orderReducer from './slices/order';
import courseReducer from './slices/course';
import videoReducer from './slices/video';
import trainerReducer from './slices/trainer';
import countryReducer from './slices/country';
import constantReducer from './slices/constant';
import notificationReducer from './slices/notification';
import socialsReducer from './slices/socials';
import analyticsReducer from './slices/analytics';
import versionReducer from './slices/version';
import useDeskReducer from './slices/usedesk';
import agentReducer from './slices/agent';
import gradeReducer from './slices/grades';
import commentReducer from './comment/slice';
import dictionaryAutomoderationReducer from './slices/dictionaryAutoModeration';
import referralProgrammeReducer from './slices/referralProgramme';
import promocodeReducer from './slices/promocode';
import bannerReducer from './slices/banner';
import storyReducer from './slices/story';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  client: clientReducer,
  product: productReducer,
  order: orderReducer,
  course: courseReducer,
  trainer: trainerReducer,
  video: videoReducer,
  country: countryReducer,
  constant: constantReducer,
  notification: notificationReducer,
  socials: socialsReducer,
  analytics: analyticsReducer,
  version: versionReducer,
  usedesk: useDeskReducer,
  agent: agentReducer,
  grade: gradeReducer,
  comment: commentReducer,
  dictionaryAutomoderation: dictionaryAutomoderationReducer,
  referralProgramme: referralProgrammeReducer,
  promocode: promocodeReducer,
  banner: bannerReducer,
  story: storyReducer,
});

export { rootPersistConfig, rootReducer };
