import { createSlice } from '@reduxjs/toolkit';

import { OrderState } from '../../@types/order';
import { INITIAL_PER_PAGE } from 'src/config';

const initialState: OrderState = {
  isLoading: false,
  orderList: {},
  orderListParams: { per_page: INITIAL_PER_PAGE },
  orderClientList: {},
  orderClientListParams: { per_page: INITIAL_PER_PAGE },
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getOrderListSuccess(state, action) {
      state.isLoading = false;
      state.orderList = action.payload;
    },

    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },

    setOrderListParams(state, action) {
      state.orderListParams = action.payload;
    },

    getOrderClientListSuccess(state, action) {
      state.isLoading = false;
      state.orderClientList = action.payload;
    },

    setOrderClientListParams(state, action) {
      state.orderClientListParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getOrderListSuccess,
  getOrderSuccess,
  setOrderListParams,
  getOrderClientListSuccess,
  setOrderClientListParams,
} = slice.actions;
