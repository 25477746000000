import { createSlice } from '@reduxjs/toolkit';

import { ProductState } from '../../@types/product';
import { INITIAL_PER_PAGE } from 'src/config';

const initialState: ProductState = {
  isLoading: false,
  productList: {},
  productListParams: { per_page: INITIAL_PER_PAGE },
  allProducts: {},
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getProductListSuccess(state, action) {
      state.isLoading = false;
      state.productList = action.payload;
    },

    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    getAllProductsSuccess(state, action) {
      state.isLoading = false;
      state.allProducts = action.payload;
    },

    setProductListParams(state, action) {
      state.productListParams = action.payload;
    },

    clearProduct(state) {
      state.product = undefined;
      state.productListParams = {};
    },

    clearProductList(state) {
      state.productList = {};
      state.productListParams = {};
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getProductListSuccess,
  getProductSuccess,
  setProductListParams,
  getAllProductsSuccess,
  clearProduct,
  clearProductList,
} = slice.actions;
