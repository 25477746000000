import { createSlice } from '@reduxjs/toolkit';

import { DictionaryAutomoderation } from 'src/@types/dictionaryAutoModeration';
import { INITIAL_PER_PAGE } from 'src/config';

const initialState: DictionaryAutomoderation = {
  isLoading: false,
  dictionaryAutomoderationList: {},
  dictionaryAutomoderationListParams: { per_page: INITIAL_PER_PAGE },
  dictionaryAutomoderation: null,
};

const slice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getDictionaryAutomoderationList(state, action) {
      state.isLoading = false;
      state.dictionaryAutomoderationList = action.payload;
    },

    getAutomoderationWord(state, action) {
      state.dictionaryAutomoderation = action.payload;
    },

    setDictionaryAutomoderationListParams(state, action) {
      state.dictionaryAutomoderationListParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getDictionaryAutomoderationList,
  getAutomoderationWord,
  setDictionaryAutomoderationListParams,
} = slice.actions;
