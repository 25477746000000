// ----------------------------------------------------------------------

import { CommonState } from './common';

export const enum VideoSource {
  KINESKOPE = 1,
  BOOMSTREAM = 2,
}
export const VIDEO_SOURCES = [
  { id: VideoSource.KINESKOPE, title: 'Kinescope' },
  { id: VideoSource.BOOMSTREAM, title: 'Boomstream' },
];

export type Video = {
  id: string;
  project_id: string;
  folder_id: string | null;
  player_id: string;
  version: number;
  title: string;
  subtitle: string;
  description: string;
  status: string;
  progress: number;
  duration: number;
  assets: [];
  chapters: [];
  privacy_type: string;
  privacy_domains: [];
  privacy_share: [];
  tags: [];
  poster: {};
  additional_materials: [];
  additional_materials_enabled: boolean;
  play_link: string;
  created_at: string;
  updated_at: string;
  subtitles: [];
  subtitles_enabled: boolean;
  hls_link: string;
};

export type VideoState = CommonState & {
  videoList: Record<VideoSource, Video[]>;
};

export type VideoUpload = {
  filename: string;
  title: string;
  link: string;
};
