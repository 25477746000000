import { dispatch } from '../store';
import ApiClients from '../../utils/axios';
import { startLoading, hasError } from '../slices/story';
import { StoryListParams, ParamsStoryForForm } from 'src/@types/story';
import { createAppAsyncThunk } from 'src/utils/createAppAsyncThunk';

const { axiosApp } = ApiClients;

export const getStoryListThunk = createAppAsyncThunk(
  'story/getStoryListThunk',
  async (params: StoryListParams, { rejectWithValue, extra }) => {
    try {
      return (await extra.api.getStoryList(params)).data;
    } catch (error: unknown) {
      //TODO: добавить обработку ошибок
      return rejectWithValue('ошибка загрузки списка сторис');
    }
  }
);

export const getStoryThunk = createAppAsyncThunk(
  'story/getStoryThunk',
  async (id: number, { rejectWithValue, extra }) => {
    try {
      return (await extra.api.getStory(id)).data.data;
    } catch (error: unknown) {
      //TODO: добавить обработку ошибок
      return rejectWithValue('ошибка загрузки сторис');
    }
  }
);

export const editStoryThunk = createAppAsyncThunk(
  'story/editStoryThunk',
  async (params: ParamsStoryForForm, { rejectWithValue, extra }) => {
    try {
      return (await extra.api.editStory(params)).data.data;
    } catch (error: unknown) {
      //TODO: добавить обработку ошибок
      return rejectWithValue('ошибка обновления сторис');
    }
  }
);

export function createStoryThunk(params: ParamsStoryForForm) {
  return async () => {
    dispatch(startLoading());
    try {
      await axiosApp.post(`stories`, {
        ...params,
      });

      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteStoryThunk(id: number) {
  return async () => {
    dispatch(startLoading());
    try {
      await axiosApp.delete(`stories/${id}`);

      dispatch(getStoryListThunk({}));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
