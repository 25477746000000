import axios, { AxiosRequestConfig } from 'axios';
import { handleTokenExpired, isValidToken, setSession } from './jwt';
import { ITokens } from '../@types/auth';
import { HOST_API } from 'src/config';

const $api = axios.create();

interface IRequestQueueElem {
  resolve: (value: string | PromiseLike<string>) => void;
  reject: (reason?: any) => void;
}

let isActiveRequest = true;
let requestQueue: IRequestQueueElem[] = [];

const attachTokenToRequest = (request: AxiosRequestConfig<any>, token: string) => {
  if (!request?.headers) return;
  request.headers.Authorization = token;
};

const processQueue = (error: unknown, token: string | null = null) => {
  requestQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      if (token === null) return;
      prom.resolve(token);
    }
  });
  requestQueue = [];
};

const refreshUrl = HOST_API + 'users/refresh';

// Установка перехватчиков для добавления токенов
$api.interceptors.request.use(
  async (request) => {
    const tokens = localStorage.getItem('tokens');
    const accessToken = tokens && JSON.parse(tokens).access_token;

    if (tokens && !isValidToken(accessToken) && isActiveRequest && request.url !== refreshUrl) {
      isActiveRequest = false;
      if (tokens)
        try {
          const response = await $api.post(refreshUrl, {
            refresh_token: JSON.parse(tokens).refresh_token,
          });
          const responseTokens: ITokens = {
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token,
          };
          setSession(responseTokens);
          if (request.headers) request.headers.Authorization = responseTokens.access_token;
          processQueue(null, responseTokens.access_token);
        } catch (error) {
          handleTokenExpired();
        }
      requestQueue = [];
      isActiveRequest = true;

      return request;
    } else if (!isActiveRequest && request.url !== refreshUrl) {
      await new Promise<string>((resolve, reject) => requestQueue.push({ resolve, reject }))
        .then((token) => attachTokenToRequest(request, token))
        .catch((error) => {
          console.error('Token refresh failed', error);
          return Promise.reject('Token refresh failed');
        });
      return request;
    } else {
      if (request.headers) request.headers.Authorization = accessToken;

      return request;
    }
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default $api;
