import { createSlice } from '@reduxjs/toolkit';
import { VideoSource, VideoState } from '../../@types/video';

const initialState: VideoState = {
  isLoading: false,
  videoList: {
    [VideoSource.KINESKOPE]: [],
    [VideoSource.BOOMSTREAM]: [],
  },
};

const slice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getVideoListSuccess(state, action) {
      state.isLoading = false;

      if (action.payload[0] === VideoSource.KINESKOPE) {
        state.videoList[VideoSource.KINESKOPE] = action.payload[1];
      } else if (action.payload[0] === VideoSource.BOOMSTREAM) {
        state.videoList[VideoSource.BOOMSTREAM] = action.payload[1];
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, resetError, getVideoListSuccess } = slice.actions;
