import { createSlice } from '@reduxjs/toolkit';

import { PromoState } from 'src/@types/promocode';
import { INITIAL_PER_PAGE } from 'src/config';

const initialState: PromoState = {
  isLoading: false,
  promotionList: {},
  promotionListParams: { per_page: INITIAL_PER_PAGE },
  promotion: null,
  promocodeList: {},
};

const slice = createSlice({
  name: 'promocode',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getPromotionList(state, action) {
      state.isLoading = false;
      state.promotionList = action.payload;
    },

    getPromotion(state, action) {
      state.isLoading = false;
      state.promotion = action.payload;
    },

    getPromocodeList(state, action) {
      state.isLoading = false;
      state.promocodeList = action.payload;
    },

    setPromotionListParams(state, action) {
      state.promotionListParams = action.payload;
    },

    clearPromocode(state) {
      state.promotion = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getPromotionList,
  getPromocodeList,
  setPromotionListParams,
  getPromotion,
  clearPromocode,
} = slice.actions;
